<template>
  <v-container
    fluid
    class="pb-7"
  >
    <v-progress-linear
      v-if="carregando"
      indeterminate
    />
    <v-row
      class="mt-1"
    >
      <v-col cols="6">
        <v-btn
          color="primary"
          small
          @click="mostrarDialogAdicionar"
        >
          <v-icon left>
            mdi-plus
          </v-icon>
          Adicionar
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-if="Object.keys(enderecos).length <= 0 && !carregando">
      <v-col
        cols="12"
      >
        <i
          class="grey--text"
        >
          Nenhum endereço adicionado
        </i>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col
        v-for="(endereco) in enderecos"
        :key="endereco.id"
        cols="12"
        md="6"
        class="py-0"
      >
        <v-list
          two-line
        >
          <v-list-item
            :key="endereco.id"
          >
            <v-list-item-content>
              <v-list-item-title>{{ endereco.endereco.logradouro }}</v-list-item-title>
              <v-list-item-subtitle>
                {{ endereco.endereco.numero || '' }}
                •
                {{ endereco.endereco.complemento || '' }}
                •
                {{ endereco.endereco.bairro || '' }}
                •
                {{ endereco.endereco.cep || '' }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-progress-circular
                v-if="carregandoDeletar && idDeletar == endereco.id"
                indeterminate
                color="red"
                :size="20"
                :width="2"
              />

              <v-btn
                v-else
                icon
                @click="deletar(endereco)"
              >
                <v-icon
                  color="grey lighten-1"
                >
                  mdi-delete
                </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>

    <dialog-endereco
      :mostrar="mostrarDialogEndereco"
      :endereco="enderecoEditar"
      :carregando-salvar="carregandoSalvar"
      :validacao-formulario="validacaoFormulario"
      @fechar="mostrarDialogEndereco = false"
      @salvar="salvar"
    />
  </v-container>
</template>

<script>
  import pessoasApi from '@/api/pessoas'

  export default {

    components: {
      DialogEndereco: () => import('@/components/dialog/DialogEndereco'),
    },

    props: {
      pessoaId: {
        type: Number,
        default: 0,
      },
    },

    data () {
      return {
        carregando: false,
        enderecos: [],
        enderecoEditar: null,
        mostrarDialogEndereco: false,
        carregandoSalvar: false,
        carregandoDeletar: false,
        validacaoFormulario: {},
        idDeletar: null,
      }
    },

    watch: {
      pessoaId: {
        immediate: true,
        handler (id) {
          if (id) {
            this.buscar()
          }
        },

      },
    },

    methods: {
      async buscar () {
        try {
          this.carregando = true
          const resposta = await pessoasApi.listarEnderecos(this.pessoaId)
          this.enderecos = resposta.data
        } catch (e) {
          console.error(e)
        } finally {
          this.carregando = false
        }
      },

      async salvar (endereco) {
        try {
          this.carregandoSalvar = true

          endereco.pessoa_id = this.pessoaId
          endereco.tipo_endereco_id = 1

          await pessoasApi.inserirEndereco(this.pessoaId, endereco)

          this.enderecoEditar = {}
          this.mostrarDialogEndereco = false
          this.$snackbar.mostrar({ cor: 'success', mensagem: this.$mensagens._('sucesso_adicionar') })
          this.buscar()
        } catch (e) {
          if (e.response.status === 422) {
            this.validacaoFormulario = this.$erroApi.validacao(e)
            return
          }

          this.$snackbar.mostrar({ cor: 'error', mensagem: this.$erroApi._(e) })
        } finally {
          this.carregandoSalvar = false
        }
      },

      mostrarDialogAdicionar () {
        this.enderecoEditar = {}
        this.validacaoFormulario = {}
        this.mostrarDialogEndereco = true
      },

      async deletar (endereco) {
        try {
          this.carregandoDeletar = true
          this.idDeletar = endereco.id
          await pessoasApi.deletarEndereco(this.pessoaId, endereco.id)
          this.$snackbar.mostrar({ cor: 'success', mensagem: this.$mensagens._('sucesso_deletar') })
        } catch (e) {
          this.$snackbar.mostrar({ cor: 'error', mensagem: this.$erroApi._(e) })
        } finally {
          this.carregandoDeletar = false
          this.idDeletar = null
          this.buscar()
        }
      },

    },
  }
</script>
